.layout {
    /* display: flex; */
    /* width: 100%; */
    justify-content: center;
    /* align-items: center; */
    /* background: #ECEFF4; */
    padding: 20px;
    height: 96vh;
}

.import-slot-layout {
    display: flex;
    /* width: 100%; */
    gap: 20px;
    /* padding: 20px 20px; */
}

.import-slot-layout > div {
    /* width: 50%; */
}

.import-slot-layout > div.L {
    width: 60%;
}
.import-slot-layout > div.R {
    width: 40%;
}