.export-layout {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 20vh;
}
.export-layout > .L > p {
    font-size: 24px;
}
.export-layout > .R {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-inline: 24px;
}
.export-layout > .R > .form {
    display: flex;
    gap: 24px;
}

.p-button {
    background-color: #63C668;
    border: 1px solid #63C668;
}