html,body {
    margin: 0;
}

.ant-select-selection-overflow {
    row-gap: 4px;
}
.ant-tag {
    font-size: 14px;
    padding: 4px 8px;
}



.import-calendar {
    width: 100%;
    /* height: 100%; */
    border: 1px solid transparent;
    transition: 0.3s;
}
.import-calendar.error {
    
    border: 1px solid #FF4D4F;
    border-radius: 8px;
}

.import-calendar .p-datepicker.p-component{
    padding-bottom: 10%;    
}

.import-calendar .p-datepicker table th {
    padding: 2rem 1rem;
}
.import-calendar .p-datepicker table td > span.p-highlight {
    color: #FFF; 
    background: #DDB05B;
}


.import-calendar .p-highlight.p-disabled {
    background: #FFF2F0 !important;
    color: #FF4D4F !important;
    opacity: 0.8;
} 

.import-calendar .p-datepicker table td > span {
    width: 50px;
    height: 50px;
}

.import-calendar .p-datepicker table td.p-datepicker-today > span {
    color: #000; 
    background: #FFF;

}


.action-form {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.export-dropdown {
    max-width: 290px;
    width: 25vw;
}

.export-calendar .p-highlight.p-disabled {
    background: #FFF2F0 !important;
    color: #FF4D4F !important;
    opacity: 0.8;
} 

.export-calendar .p-datepicker table td > span {
    width: 50px;
    height: 50px;
}


/* custom */
.btn-hl {
    background-color: #493B43;
    transition: 0.3s;
    line-height: 41px;
    color: #ffffff;
    background: #493B43;
    border-color: #493B43;
    border: 0px;
    font-size: 16px;
    min-width: 180px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    cursor: pointer;

    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.btn-hl:hover {
    background-color: #73636C;
    border-color: #73636C;
}

.tTitleBooking {
    font-weight: 500; 
    text-align: center; 
    margin-top: 1cm;
    margin-bottom: 16px;
    font-size: 24px;
}